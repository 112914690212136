import { OneColumnPageLayout } from '@design-system/layouts/oneColumnPageLayout'
import { LayoutContentWidth } from '@design-system/styles/theme'
import { css } from '@emotion/react'
import { SkeletonSavingsBreakdown } from '@design-system/components/widgets/savingsPotentialRoboAdviceWidget/skeletons/skeletonSavingsBreakdown'
import { Card } from '@design-system/components/card'

export const PageLoading = () => {
    return (
        <div
            css={css`
                padding-left: calc((100% - ${LayoutContentWidth.WIDE}) / 2);
                padding-right: calc((100% - ${LayoutContentWidth.WIDE}) / 2);
                width: 100%;
                margin: auto;
            `}
        >
            <div
                css={css`
                    max-width: ${LayoutContentWidth.SMALL_MEDIUM};
                    margin: 60px auto;
                `}
            >
                <OneColumnPageLayout>
                    <SkeletonSavingsBreakdown />
                </OneColumnPageLayout>
            </div>
        </div>
    )
}

export default PageLoading
