import { HandlerGetAllBuildingsResponseData } from '@pages/api/buildings/types'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { cookieName } from '@design-system/utils/firebaseAuthentication'
import { useMe } from '@api/auth/hooks/useMe'

// TODO: Remove `New` suffix
const useGetAllBuildingsNew = (enabled = true) => {
    const idToken = localStorage.getItem(cookieName)

    const { me } = useMe()

    const {
        data: buildings,
        error: getBuildingsError,
        isLoading: isLoadingBuildings,
        isRefetching: isRefetchingBuildings,
        refetch: refetchBuildings,
    } = useQuery({
        queryKey: ['getAllBuildings_new'], // TODO: Remove `_new` suffix
        queryFn: async () => {
            const { data: responseData } = await axios.get<HandlerGetAllBuildingsResponseData>('/api/buildings', {
                headers: {
                    'x-id-token': idToken!.toString(),
                    'x-use-user-service':
                        me?.email?.toLowerCase().includes('@enter') ||
                        me?.email?.toLowerCase().includes('@radyant') ||
                        'false',
                },
            })

            return responseData.data
        },
        enabled,
        retry: 0,
        throwOnError: (error: any) => error.response?.status >= 500,
    })

    return {
        buildings,
        isLoadingBuildings,
        isRefetchingBuildings,
        refetchBuildings,
        getBuildingsError,
    }
}

export default useGetAllBuildingsNew
