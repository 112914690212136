import { User } from '@pages/api/auth/me'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Page } from '@utils/urls'
import axios from 'axios'
import { cookieName, signOut } from '@design-system/utils/firebaseAuthentication'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useAuthentication } from '@design-system/components/authentication/hooks/useAuthentication'
import { datadogRum } from '@datadog/browser-rum'

export const useMe = (ignoreError = false, firstRun = false): { me: User; isMeLoading: boolean } => {
    const qc = useQueryClient()

    const router = useRouter()
    const { refreshToken } = useAuthentication()
    const isUnauthorizedPage = router.asPath.includes('/auth/') || router.asPath.includes('/offers/overview')

    let idToken = localStorage.getItem(cookieName)
    const isIdTokenPresent = Boolean(idToken)

    const [abortController] = useState(new AbortController())

    useEffect(() => {
        if (!ignoreError && !isUnauthorizedPage && !isIdTokenPresent) {
            abortController.abort()
            router.push(Page.LOGIN)
        }
    }, [isUnauthorizedPage, ignoreError, isIdTokenPresent])

    const {
        data: me,
        isError,
        isLoading: isMeLoading,
    } = useQuery({
        queryKey: ['me'],
        queryFn: async () => {
            if (firstRun) {
                await refreshToken()
                idToken = localStorage.getItem(cookieName)
            }
            try {
                const { data } = await axios<User>('/api/auth/me', {
                    headers: {
                        'x-id-token': idToken,
                        Referer: 'customer-portal',
                    },
                    signal: abortController.signal,
                })

                return data
            } catch (error: any) {
                if (error.response?.status >= 400 && error.response?.status < 500) {
                    return
                } else {
                    datadogRum.addError(error)
                    console.error(error)
                }
            }
        },
        enabled: router.isReady,
        throwOnError: (error: any) => error.response?.status >= 500,
    })

    useEffect(() => {
        ;(async () => {
            if (isError && isIdTokenPresent) {
                await signOut()
                qc.clear()

                if (!isUnauthorizedPage && !ignoreError) {
                    router.push(Page.LOGIN)
                }
            }
        })()
    }, [isError, isIdTokenPresent])

    return {
        me: me!,
        isMeLoading,
    }
}
