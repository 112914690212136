import { RoboAdviceScore, GetBuildings, FormProgressStatus } from '@design-system/types/buildings/buildingsTypes'
import { SurveyName } from '@design-system/types/buildings/buildingsTypes'

/**
 * Get scores for all parts
 * @param building
 * @returns
 */
export const getBuildingPartsRoboAdviceScores = (building: GetBuildings) => {
    return building.currentRoboAdviceVersion?.data?.roboAdviceScores?.data.filter(
        (roboAdviceScore: RoboAdviceScore) => roboAdviceScore.measure !== 'recommendedMeasures'
    )
}

export const getSurveyStatus = (surveyName: SurveyName, building?: GetBuildings): FormProgressStatus | undefined => {
    if (!building) return undefined

    switch (surveyName) {
        case SurveyName.main:
            return building.hdsStatus
        case SurveyName.appointment:
            return building.isfpStatus
        default:
            return building.serviceLineItems?.data?.[0]?.subsidiesService?.data?.measureFormProgressStatus
    }
}

const buildingLocalStorageName = 'enter-customer-buildings'

/**
 * Save user and building information in local storage.
 * @param {Object} params - Parameters for saving buildings.
 * @param {string} params.userUuid - User UUID.
 * @param {string} params.buildingUuid - Building UUID.
 */
export const saveBuildingsInLocalStorage = ({
    userUuid = '',
    buildingUuid = '',
}: {
    userUuid?: string
    buildingUuid?: string
}) => {
    localStorage.setItem(
        buildingLocalStorageName,
        JSON.stringify({
            userUuid,
            lastActiveBuildingUuid: buildingUuid,
        })
    )
}

/**
 * Get the last active building UUID from local storage based on the user UUID.
 * @param {string} uuid - User UUID.
 * @returns {string|undefined} - Last active building UUID or undefined if not found.
 */
export const getBuildingsFromLocalStorage = (uuid: string): string | undefined => {
    const buildingData = localStorage.getItem(buildingLocalStorageName)
    if (!buildingData) return

    try {
        const { userUuid, lastActiveBuildingUuid } = JSON.parse(buildingData) || {}
        if (userUuid === uuid) {
            return lastActiveBuildingUuid
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error parsing building data:', error)
    }
}
