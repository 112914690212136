import { css } from '@emotion/react'
import { NavigationHeader } from '@design-system/components/navigationHeader'
import { ChildrenProps } from '@design-system/types/propTypes'
import { ReactNode } from 'react'
import { useTheme } from '../styles/theme'

export function OneColumnPageLayout({
    children,
    pageHeader,
}: {
    pageHeader?: {
        title?: ReactNode
        subTitle?: ReactNode
        handleBackClick?: () => void
        fullwidth?: boolean
    }
} & ChildrenProps) {
    const theme = useTheme()

    return (
        <section
            css={css`
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: ${theme.spacing(3)};
                ${pageHeader?.fullwidth ? '> header p {max-width: 100%;}' : ''}
            `}
        >
            {pageHeader && <NavigationHeader {...pageHeader} />}
            {children}
        </section>
    )
}
