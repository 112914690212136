import { ReactNode } from 'react'
import { css } from '@emotion/react'

import { Button } from '@design-system/components/button/button'
import { EnterIcon, IconName } from '@design-system/enter-icons/enterIcon'
import Text from '@design-system/components/text/text'
import { EnterTheme, useTheme } from '@design-system/styles/theme'
import { useDesktop } from '@design-system/context/screenSizeContext'
import { run } from '@design-system/utils/helpers'
import { StyleProps } from '@design-system/types/propTypes'

export interface NavigationHeaderProps extends StyleProps {
    handleBackClick?: () => void
    title?: ReactNode
    renderBottomContent?: ReactNode
    renderTitleInlineEnd?: () => ReactNode
    handleBadgeClick?: () => void
    subTitle?: ReactNode
}

export const NavigationHeader = ({
    handleBackClick,
    title,
    subTitle,
    renderBottomContent,
    renderTitleInlineEnd,
    styles,
}: NavigationHeaderProps) => {
    const theme = useTheme()
    const { desktopMediaQuery, isDesktop } = useDesktop()

    return (
        <header css={[getNavigationHeaderContainerStyles(theme, desktopMediaQuery), styles]}>
            <div
                css={[
                    getTopStyles(theme, desktopMediaQuery),
                    handleBackClick ? getGridTopStyles() : getFlexTopStyles(),
                ]}
            >
                {handleBackClick && (
                    <div css={getBackButtonStyles()}>
                        <Button
                            styleType="primary-outlined"
                            onClick={handleBackClick}
                            buttonStyles={css`
                                padding: 0;
                                height: 36px;
                                width: 36px;
                                border-radius: 50%;
                            `}
                        >
                            <EnterIcon name={IconName.ChevronLeft} />
                        </Button>
                    </div>
                )}

                {run(() => {
                    if (typeof title === 'string') {
                        return (
                            <Text
                                as="h1"
                                variant="headline"
                                size={isDesktop ? 'large' : 'small'}
                                css={getTitleStyles()}
                            >
                                {title}
                            </Text>
                        )
                    }

                    return title
                })}
                {run(() => {
                    if (typeof subTitle === 'string') {
                        return (
                            <Text as="p" variant="title" size="small" css={getSubTitleStyles(theme)}>
                                {subTitle}
                            </Text>
                        )
                    }

                    return subTitle
                })}

                {renderTitleInlineEnd && <div css={getTitleEndStyles()}>{renderTitleInlineEnd()}</div>}
            </div>

            {renderBottomContent && <div css={getBottomStyles()}>{renderBottomContent}</div>}
        </header>
    )
}

export const getNavigationHeaderContainerStyles = (theme: EnterTheme, desktopMediaQuery: string) => css`
    // When scrolling, the navigation header should be shown below the navigation itself.
    z-index: ${theme.zIndex.navigation - 1};
    display: grid;
    grid-template-areas:
        'top-content'
        'bottom-content';

    ${desktopMediaQuery} {
        position: unset;
        padding-top: unset;
    }
`

const getFlexTopStyles = () => css`
    display: flex;
    flex-direction: column;
`

const getGridTopStyles = () => css`
    display: grid;
    align-items: center;
`

const getTopStyles = (theme: EnterTheme, desktopMediaQuery: string) => css`
    row-gap: ${theme.spacing(2)};
    grid-area: top-content;
    grid-template-columns: 36px 1fr auto;
    row-gap: ${theme.spacing(1)};
    column-gap: ${theme.spacing(2)};
    grid-template-areas:
        'button title title-end'
        'subtitle subtitle .';

    ${desktopMediaQuery} {
        justify-content: start;
        grid-template-columns: 36px 1fr auto;
        column-gap: ${theme.spacing(2)};
        grid-template-areas:
            'button title title-end'
            '. subtitle .';
    }
`

const getBottomStyles = () => css`
    grid-area: bottom-content;
`

const getBackButtonStyles = () => css`
    grid-area: button;
`
const getTitleStyles = () => css`
    grid-area: title;
`

const getTitleEndStyles = () => css`
    grid-area: title-end;
    min-width: fit-content;
`

const getSubTitleStyles = (theme: EnterTheme) => css`
    color: ${theme.colors.text.secondary.onLight};
    max-width: 550px;
    grid-area: subtitle;
`
